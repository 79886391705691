import React from 'react'
import { Box, TopNav, IconHamburger, IconSocialGithub } from '@patreon/studio'
import { useCurrentDoc } from 'docz'

export const Header = ({ onOpen }) => {
    const doc = useCurrentDoc()
    return (
        <TopNav
            logo={{ type: TopNav.LogoType.Wordmark, url: '/' }}
            title={`Stele - ${doc.name}`}
            itemsRight={[
                {
                    icon: IconSocialGithub,
                    url: 'https://www.github.com/patreon/stele',
                },
                {
                    customNode: (
                        <Box
                            onClick={onOpen}
                            display={{
                                sm: Box.Display.Flex,
                                lg: Box.Display.None,
                            }}
                        >
                            <IconHamburger />
                        </Box>
                    ),
                },
            ]}
            notFixed
        />
    )
}
