import React from 'react'
import { ThemeProvider } from 'styled-components'
import { themeRefresh, Box, GlobalStyles, Text } from '@patreon/studio'
import { Helmet } from 'react-helmet-async'

export default ({ children }) => (
    <ThemeProvider theme={themeRefresh}>
        <>
            <Helmet>
                <link
                    href="https://c5.patreon.com/external/fonts/aktiv-grotesk.css"
                    rel="stylesheet"
                ></link>
                <link
                    href="https://c5.patreon.com/external/fonts/gt-walsheim.css"
                    rel="stylesheet"
                ></link>
            </Helmet>
            <GlobalStyles />
            {children}
            <Box ph={2} pb={2}>
                <Text el={Text.Element.Div}>© Patreon</Text>
            </Box>
        </>
    </ThemeProvider>
)
